body {
  margin: 0;
 
  font-family: 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.wa-float{
	position: fixed;
  width: 42px;
  height: 42px;
  bottom: 40px;
  right: 40px;
  display: none;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 24px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.wa-float-icon {
	margin-top:10px;
}

@media only screen and (max-width: 600px) {
  .wa-float {
    display: block;
  }
}